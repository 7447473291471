export default {
  methods: {
    formatField(type, field) {
      if (type === 'number') {
        return parseInt(field, 10).toLocaleString();
      }

      if (type === 'currency') {
        return parseInt(field, 10).toLocaleString(undefined, {
          style: 'currency',
          currency: 'USD',
        });
      }

      if (type === 'array') {
        return field.join(', ');
      }

      if (type === 'url') {
        if (!/^(f|ht)tps?:\/\//i.test(field)) {
          return `https://${field}`;
        }
        return field;
      }

      return field;
    },
  },
};
