<template>
  <div v-if="rankingCriteria.value" class="column is-12 is-paddingless">
    <span v-if="rankingCriteria.label" v-html="rankingCriteria.label" />

    <span v-html="formatField(rankingCriteria.type, rankingCriteria.value)" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import formatField from '@/mixins/fields/formatField';

export default {
  mixins: [formatField],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      primaryFilter: 'directory/primaryFilter',
      currentDirectory: 'directory/current',
    }),
    ...mapState({
      routeQuery: (state) => state.route.query,
    }),
    rankingCriteria() {
      // Get the first primary filter value in the URL
      // This only gets the _first_ one if multiple are selected
      const routeField = this.routeQuery?.[this.primaryFilter?.key]?.split('~')?.[0];

      // Get the primary filter field of the record
      const recordField = this.primaryFilter?.key.split('.')
        /* eslint-disable arrow-body-style */
        // Disables this so that the method doesn't go off the side of the page
        .reduce((record, key) => {
          return record?.[key] ?? record.find((field) => field[key] === routeField);
        }, this.record);
        /* eslint-enable */

      // Gets the field value for the filter on the record
      const fieldType = this.getRecordFieldType(recordField);

      return this.getRecordPrimaryField(fieldType, recordField, routeField);
    },
  },
  methods: {
    getRecordFieldType(recordField) {
      if (Array.isArray(recordField)) {
        return 'array';
      }

      if (typeof recordField === 'object' && recordField !== null) {
        return 'object';
      }

      // String should be the fallback value
      return 'string';
    },
    getRecordPrimaryField(fieldType, recordField, routeField) {
      if (fieldType === 'object') {
        return {
          label: recordField.field,
          value: recordField.value,
          type: this.currentDirectory.fields
            .find((field) => field.name === recordField.field)?.type ?? this.primaryFilter?.type,
        };
      }

      if (fieldType === 'string') {
        return {
          label: null,
          value: recordField,
          type: fieldType,
        };
      }

      console.log(recordField);
      return {
        label: null,
        value: recordField?.find((field) => field === routeField) ?? recordField?.[0] ?? null,
        // Only change field type to 'string' if it was an array
        // because we only ever show 1 value, never a list of them
        type: fieldType === 'array' ? 'string' : this.primaryFilter?.type,
      };
    },
  },
};
</script>
